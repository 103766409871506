$mega-menu__margin                           : 0 !default;
$mega-menu__border-bottom                    : 1px solid $gray-light !default;
$mega-menu__font-color                       : $gray-darker !default;
$mega-menu__font-weight                      : $font-weight-bold !default;
$mega-menu__font-size                        : $font-size-base !default;
$mega-menu__font-size--large                 : 16px !default;
$mega-menu__font-size--small                 : $font-size-small !default;
$mega-menu__background-color                 : $white !default;

$mega-menu__list-justify-content             : flex-start !default;
$mega-menu__list-background-color            : $white !default;

$mega-menu__inner-list-padding               : 0 !default;
$mega-menu__inner-list-background-color      : $white !default;
$mega-menu__inner-list-box-shadow--level1    : 0 4px 6px 0 rgba(51, 51, 51, 0.3) !default;
$mega-menu__inner-list-border--level1        : 1px solid $gray-light !default;

$mega-menu__inner-item-padding--parent       : 16px 0 16px 16px !default;
$mega-menu__inner-item-padding--parent-child : 0 !default;
$mega-menu__inner-item-maargin--parent-child : 0 0 $spacer--medium !default;

$mega-menu__inner-item-padding--image        : 0 !default;
$mega-menu__inner-item-max-width--image      : 210px;

$mega-menu__inner-item-icon-color            : $color-primary !default;
$mega-menu__inner-item-icon-size             : 14px !default;

$mega-menu__link-padding                     : 0px $spacer--medium !default;
$mega-menu__link-margin                      : 0px $spacer--medium !default;
$mega-menu__link-color                       : $gray-darker !default;
$mega-menu__link-color--active               : $color-primary !default;
$mega-menu__link-text-decoration             : none !default;

$mega-menu__inner-link-margin                : 0 !default;
$mega-menu__inner-link-padding               : 0 !default;
$mega-menu__inner-link-font-weight           : $font-weight-normal !default;
$mega-menu__inner-link-text-decoration       : none !default;
$mega-menu__inner-link-color                 : $gray-darker !default;
$mega-menu__inner-link-color--active         : $gray-darker !default;
$mega-menu__inner-link-color--image-focus    : $font-color-base !default;
$mega-menu__inner-link-text-decoration--active : none !default;
$mega-menu-after-icon-fill                   : $color-primary !default;
$mega-menu-height                            : 3.5em !default;

$side-menu__trigger-wrapper-border      : 1px solid $gray-light !default;
$side-menu__trigger-wrapper-background  : $gray-lightest !default;
$side-menu__trigger-size                : 41px !default;
$side-menu__trigger-margin              : 0 !default;
$side-menu__trigger-padding             : 8px !default;
$side-menu__trigger-border              : 1px solid $gray-light !default;
$side-menu__trigger-border-radius       : $border-radius;
$side-menu__trigger-background-color    : $white !default;
$side-menu__trigger-box-shadow          : none !default;
$side-menu__trigger-icon-size           : 18px !default;
$side-menu__trigger-icon-color          : $color-primary !default;
$side-menu__trigger-icon-size--close    : 42px !default;
$side-menu__title-font-size             : $font-size-medium !default;
$side-menu__title-font-weight           : $font-weight-bold !default;

body.mobile-menu-open {
    overflow-y: hidden;
}

$self: '.mega-menu';

body.new-menu {
    &.mobile-menu-open .mega-menu {
        display: block;
        width: 100%;
        transform: translateX(0);
    }

    .mega-menu {
        position: absolute;
        top: 0;
        height: 100%;
        z-index: 999;
        background: #fff;
        overflow-y: scroll;
        transition: transform .3s ease-in-out, outline-offset 1ms;
        transform: translateX(-100%);
        color: $color-text-accent;

        @include mq($screen-m) {
            position: relative;
            height: auto;
            overflow: visible;
            transition: none;
            transform: none;
            background: $mega-menu__background-color;
            color: $mega-menu__font-color;
            margin: $mega-menu__margin;
            border-bottom: $mega-menu__border-bottom;
        }

        &__side-menu {
            order: 4;
            flex: 0 auto;
            margin-top: 7px;

            @include mq($screen-m) {
                display: none;
            }
        }

        &__wrapper {
            display: flex;

            @include mq(false, $screen-m) {
                display: block;
                margin: 0;
                width: 100%;
                height: 100%;
            }
        }

        &__list {
            position: relative;
            display: block;
            flex-wrap: wrap;
            list-style: none;
            width: 100%;
            margin: 0;
            padding: 0;

            @include mq($screen-m) {
                position: static;
                display: flex;
                flex-wrap: nowrap;
                width: 80%;
                justify-content: $mega-menu__list-justify-content;
                background-color: $mega-menu__list-background-color;

                @include isiOSDevice {
                    width: 70%;
                }
            }
        }

        &__item,
        &__inner-item {
            @include mq(false, $screen-m) {
                flex-basis: 100%;
                padding: 0 15px;
                border-bottom: $border-base;

                a {
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 0;
                        width: 10px;
                        height: 10px;
                        transform: translateY(-50%);
                        background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 11 16"><path fill="#{$color-text-accent}" d="M2.33 0L.45 1.88 6.55 8l-6.1 6.12L2.33 16l8-8z"/></svg>') no-repeat center;
                        background-size: contain;
                    }
                }
            }
        }

        &__item {
            @include mq($screen-m) {
                border: none;
                padding: 0;
            }

            &--image {
                display: none;

                @include mq($screen-m) {
                    &.open {
                        display: block;
                        z-index: 55;
                        position: absolute;
                        top: 100px;
                        right: calc((100% - #{$max-content-width}) / 2 + 60px);
                        max-width: $mega-menu__inner-item-max-width--image;
                        padding: $mega-menu__inner-item-padding--image;
                    }

                    img {
                        width: auto;
                    }
                }
            }
        }

        &__list-secondary {
            display: none;

            @include mq($screen-m) {
                position: relative;
                display: flex;
                margin: 0;
                flex-wrap: nowrap;
                justify-content: $mega-menu__list-justify-content;
                padding: 0;
                list-style: none;
                background-color: $mega-menu__list-background-color;
                width: 20%;

                @include isiOSDevice {
                    width: 30%;
                }
            }
        }

        &__inner-list {
            padding: $mega-menu__inner-list-padding;
            background-color: $mega-menu__inner-list-background-color;
            list-style: none;

            &--level1,
            &--level2 {
                &.open {
                    position: absolute;
                    display: block;
                    flex-flow: row wrap;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;

                    .mega-menu__inner-item.mega-menu__inner-item--level2.mega-menu__inner-item--parent {
                        border: 0;
                    }
                }
            }

            &--level1 {
                display: none;
                position: absolute;
                flex-wrap: wrap;
                top: 100%;
                left: 0;

                @include mq($screen-m) {
                    z-index: 4;
                    flex-direction: column;
                    max-width: none;
                    width: 100%;
                    padding: 0 calc((100% - #{$max-content-width}) / 2);
                    border-top: $mega-menu__inner-list-border--level1;
                    box-shadow: $mega-menu__inner-list-box-shadow--level1;
                    min-height: 500px;

                    &:before {
                        content: '';
                        background: #f9f9f9;
                        height: 450px;
                        position: absolute;
                        left: calc((100% - #{$max-content-width}) / 2);
                        bottom: 0;
                        width: calc(#{$max-content-width} / 4);
                        z-index: -1;
                    }
                }

                &.open {
                    z-index: 50;
                }
            }

            &--level2 {
                display: none;

                &.open {
                    z-index: 60;

                    @include mq($screen-m) {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: space-between;
                        width: calc(#{$max-content-width} / 2);
                        height: auto;
                        padding-top: 10px;
                        left: calc(((100% - #{$max-content-width}) / 2) + (#{$max-content-width} / 4) + 25px);
                    }
                }
            }
        }

        &__inner-item {
            @include mq($screen-m) {
                &--level1 {
                    & .mega-menu__inner-link {
                        display: flex;
                        margin: 10px 20px 0;
                        padding: 5px;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                &--parent {
                    & .mega-menu__inner-item--level2 .mega-menu__inner-link {
                        margin-top: 0;
                    }

                    & > .mega-menu__inner-link {
                        &:after {
                            content: '';
                            display: inline-block;
                            width: 10px;
                            height: 10px;
                            background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16"><path fill-rule="evenodd" fill="#{$mega-menu-after-icon-fill}" d="M2.33 0L.45 1.88 6.55 8l-6.1 6.12L2.33 16l8-8z"/></svg>') no-repeat center;
                            background-size: contain;
                            margin-left: 6px;
                            margin-top: 5px;
                        }
                    }
                }
            }

            &--level1 {
                @include mq($screen-m) {
                    padding: 0;
                    width: 25%;
                    background: #f9f9f9;

                    &:last-child {
                        padding-bottom: 20px;
                    }

                    &.open {
                        > a {
                            font-weight: 600;
                            text-decoration: none;
                            color: $color-primary;
                            background: $color-primary--accent-2;
                            border-radius: $border-radius;
                            padding-left: 10px;
                            margin-left: 14px;
                        }
                    }
                }
            }

            &--level2 {
                padding: 5px 0;
                flex-basis: calc(100% / 3);

                @include mq(false, $screen-m) {
                    padding: 0 15px;
                    flex-basis: 100%;
                }
            }


                &--level3 {
                    @include mq($screen-m) {
                        &:hover {
                            > a {
                                font-weight: 600;
                                text-decoration: none;
                                color: $color-primary;
                                background: $color-primary--accent-2;
                                border-radius: $border-radius;
                                padding-left: 10px;
                                margin-left: 14px;
                            }
                        }
                    }

                    @include mq(false, $screen-m) {
                        padding: 0;
                    }
                }
        }

        &__inner-item-icon {
            fill: $mega-menu__inner-item-icon-color;
            width: $mega-menu__inner-item-icon-size;
            height: $mega-menu__inner-item-icon-size;
        }

        &__link,
        &__inner-link {
            display: inline-block;
            line-height: normal;
            padding: 15px 0;
            width: 100%;
            transition: none;
            color: $color-text-accent;
        }

        &__link {
            @include mq($screen-m) {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                padding: 0 15px;
                font-size: $mega-menu__font-size--large;
                font-weight: $mega-menu__font-weight;
                color: $mega-menu__link-color;
                text-decoration: $mega-menu__link-text-decoration;
                height: $mega-menu-height;
                width: auto;

                &:hover {
                    text-decoration: $mega-menu__link-text-decoration;
                    color: $mega-menu__link-color--active;
                }
            }
        }

        &__inner-link {
            @include mq($screen-m) {
                width: auto;
                margin: $mega-menu__inner-link-margin;
                padding: $mega-menu__inner-link-padding;
                font-size: $mega-menu__font-size;
                font-weight: $mega-menu__inner-link-font-weight;
                color: $mega-menu__inner-link-color;
                text-decoration: $mega-menu__inner-link-text-decoration;

                &:hover {
                    text-decoration: $mega-menu__inner-link-text-decoration--active;
                    color: $mega-menu__inner-link-color--active;
                }
            }
        }

        &__inner-link-icon {
            align-self: baseline;
            margin-top: 4px;
        }

        &__column-hidden {
            > .mega-menu__inner-link {
                display: none;
            }
        }

        &__trigger-wrapper {
            display: flex;
            justify-content: flex-start;
            border-bottom: $side-menu__trigger-wrapper-border;
            background: $side-menu__trigger-wrapper-background;
        }

        &__trigger {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            height: $side-menu__trigger-size;
            min-width: $side-menu__trigger-size;
            margin: $side-menu__trigger-margin;
            padding: $side-menu__trigger-padding;
            border: $side-menu__trigger-border;
            border-radius: $side-menu__trigger-border-radius;
        }

        &__trigger-icon {
            width: $side-menu__trigger-icon-size;
            height: $side-menu__trigger-icon-size;
            fill: $side-menu__trigger-icon-color;
        }

        &__trigger--close {
            padding: 0;
            border: 0;
            background-color: transparent;
            align-self: center;

            .mega-menu__trigger-icon {
                width: $side-menu__trigger-icon-size--close;
                height: $side-menu__trigger-icon-size--close;
            }

            &.back {
                margin-left: 11px;

                .mega-menu__trigger-icon {
                    width: 22px;
                    height: 22px;
                }
            }
        }

        &__trigger-text {
            font-size: 12px;
            font-weight: 600;
            margin: 0 0 0 4px;
            color: $color-primary;
        }

        &__title {
            text-align: center;
            padding: 25px 0;
            width: calc(100% - (2 * #{$side-menu__trigger-size}));
            font-size: $side-menu__title-font-size;
            font-weight: $side-menu__title-font-weight;
        }

        &__heading {
            display: block;
            font-weight: bold;
            padding: 15px 0;
            color: $color-primary;

            @include mq($screen-m) {
                color: $color-text-accent;
                font-weight: bold;
                margin: 0 20px;
                padding: 5px;
            }

            &--link {
                padding: 0;
                margin: 0;
            }
        }
    }
}
