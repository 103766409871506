.modal-alternatives {
    @extend .modal-base;
    text-align: center;
    margin: 0 auto;
    width: 90%;
    height: 500px;

    @include mq($screen-m) {
        height: 100%;
        width: 25%;
        margin: 50px auto;
        box-shadow: none;
    }

    .heading {
        font-size: 17px;
        font-weight: $font-weight-bold;
    }

    &--led.modal-popup {
        .modal-header {
            padding-top: 10px;
        }
    }

    .heading--usps {
        text-align: left;
        background: #e8ffee;
        font-size: $font-size-base;
        color: $color-text-accent;
        font-weight: normal;
        padding: 15px 30px;

        h2 {
            font-size: $font-size-base;
            font-weight: $font-weight-bold;
        }

        .list--led-alternatives {
            li {
                position: relative;
                padding: 0 0 0 22px;

                &:before {
                    display: inline-block;
                    position: absolute;
                    top: 3px;
                    left: -1px;
                    content: '';
                    background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#fff" d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"/></svg>') no-repeat center;
                    width: 16px;
                    height: 16px;
                    background-color: $color-alt;
                    border-radius: 50%;

                    @include mq($screen-l) {
                        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#fff" d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"/></svg>');
                    }
                }
            }
        }
    }

    &__image-wrapper {

        img {
            max-width: 150px;
            max-height: 150px;
            margin: 0 auto;
        }
    }

    &__details {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
    }

    &__price-wrapper {
        text-align: left;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .price-box {
            margin-right: 5px;

            .price {
                font-size: $font-size-huge;
                font-weight: $font-weight-bold;
            }
        }
    }

    &__tier-price {

        @include mq($screen-m) {
            display: block;
            margin-top: $spacer--medium;
        }
    }

    .product-view__volume-discount {
      position: relative !important;
      margin: 0;

      .dropdown-item__label {
        position: relative;
      }
    }

    &__footer {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding-top: 5px;
        border-top: $border-base;

        .link {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
        }

        svg {
            width: 16px;
            height: 16px;
            margin-top: 3px;
            fill: $color-primary;
        }
    }

    &.modal-popup,
    &.modal-slide {
        left: 0 !important;

        .modal-inner-wrap {
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.35);
            border-radius: $border-radius;

            @include mq($screen-m) {
                max-height: calc(100% - 100px) !important;
            }
        }
    }
}
