$usp-bar-background: $color-primary--accent-2 !default;
$usp-bar-icon-fill: $color-alt--accent-2 !default;
$usp-bar-font-size: 15px !default;
$usp-bar-padding: 0 15px !default;
$usp-bar-font-color: $color-primary--accent-9 !default;

$usp-bar-background--single-item: $color-primary--accent-2 !default;
$usp-bar-font-size--single-item: $font-size-base !default;
$usp-bar-font-color--single-item: $color-primary--accent-9 !default;

$self : '.usp-bar';

#{$self} {
    display: none;
    background: $usp-bar-background;
    transition: 0.3s ease;
    padding: $usp-bar-padding;

    @include mq($screen-xl) {
        display: block;
    }

    &__items-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0 auto;
    }

    &__link {
        display: flex;
        padding: 15px 0;
        line-height: 1.2;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        fill: $usp-bar-icon-fill;
    }

    &__title {
        font-size: $usp-bar-font-size;
        color: $usp-bar-font-color;
    }

    &--single-item {
        background: $usp-bar-background--single-item;

        .usp-bar {

            &__items-container {
                justify-content: center;
            }

            &__title {
                font-size: $usp-bar-font-size--single-item;
                color: $usp-bar-font-color--single-item;
            }
        }
    }

    ul:not(.usp-bar__items-container) {
        @extend #{$self}__items-container;
        @extend .container;
        @extend .list;

        li:not(.usp-bar__item) {
            @extend #{$self}__link;
            @extend #{$self}__title;

            display: block;
            position: relative;
            padding-left: 30px;

            &.only-installers,
            &.brand-product-range,
            &.fast-delivery,
            &.cheaper {
                background-repeat: no-repeat;
                background-size: 30px;
                background-position: left center;
            }
            &.only-installers {
                background-image: url(../images/icons/installer.svg);
            }
            &.brand-product-range {
                background-image: url(../images/icons/premium-badge.svg);
            }
            &.fast-delivery {
                background-image: url(../images/icons/fast-delivery.svg);
            }
            &.cheaper {
                background-image: url(../images/icons/savemoney.svg);
            }

            span.title {
                font-weight: bold;
                padding: 0 $spacer;
            }

            span.description {
                display: block;
                font-style: italic;
                padding: 0 $spacer;
            }
        }
    }
}
