$self : '.related-products';
$related-products__title-color              :         $color-primary !default;
$related-products__title-color--alt         :         $color-alt !default;
$related-products__title-font-weight        :         $font-weight-semi-bold !default;
$related-products__title-font-size          :         $font-size-base !default;
$related-products__title-font-size\@medium  :         $font-size-medium !default;

#{$self} {

    &__inline-product-item {
        flex-direction: row;
        margin-bottom: $spacer--medium;

        .image {
            padding: 5px;
        }

        .amasty-label-container {
            display: none !important;
        }

        .product-view__form {
            margin-bottom: 0;

            .button--cta {
                background: $color-primary;
            }

            .button__icon {
                display: none;
            }
        }
    }

    &__title {
        flex-basis: 100%;
        font-weight: $related-products__title-font-weight;
        font-size: $related-products__title-font-size;
        color: $related-products__title-color;

        @include mq($screen-m) {
            margin-bottom: 15px;
            font-size: $related-products__title-font-size\@medium;

            &.large {
                font-size: 18px;
            }
        }

        &--alt {
            color: $related-products__title-color--alt;
        }
    }

    &__products {

        @include mq($screen-m) {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;

            .inline-product-item {
                flex-basis: 50%;
                flex-grow: 1;

                &:first-child {
                    margin-right: 5px;
                }

                &:last-child {
                    margin-left: 5px;
                }

                &:only-child {
                    margin-left: 0;
                    margin-right: 0;
                }

                &__details .inline-product-item__link {
                    flex-basis: 100%;
                }
            }
        }
    }

    &__led-banner {
        display: none;

        @include mq($screen-m) {
            display: block;
            position: relative;
            background: $color-alt;
            color: $color-light;
            text-align: center;
            border-radius: $border-radius;
            padding: 5px;
            font-weight: $font-weight-bold;
            font-size: 20px;

            &:after {
                position: absolute;
                content: '';
                left: calc(50% - 20px);
                bottom: -19px;
                width: 40px;
                transform: rotate(45deg);
                height: 40px;
                background: $color-alt;
                z-index: -1;
            }
        }
    }

    &--inline {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        #{$self}__inline-product-item {
            margin-bottom: 0;
        }
    }

    &--slider {

        #{$self}__inline-product-item {
            flex-direction: column;
            width: 130px;
            margin-bottom: 0;
        }
    }

    &.light-border-top {
        padding: 15px 0;
        border-top: $border-base;
    }
}
