$home-background-color                  : $gray-lightest !default;
$home-background-color--accent          : $color-light !default;
$home__banner-margin                    : 20px auto !default;
$home__banner-margin-\@large            : 40px auto !default;
$home__static-block-padding              : 50px 0 20px !default;
$home__featured-categories-border       : $base-fat-border !default;

$self : '.home';

#{$self} {
    background: $home-background-color;

    @include mq($screen-m) {
        background: $home-background-color--accent;
    }

    .usp-bar {
        display: block;

        @include mq($screen-m) {
            display: none;
        }
    }

    &__banner-content {
        margin: $home__banner-margin;

        @include mq($screen-m) {
            margin: $home__banner-margin-\@large;
        }
    }

    &__static-block {
        display: none;

        @include mq($screen-m, $screen-l) {
            border-bottom: 1px solid $gray-light;
        }

        @include mq($screen-m) {
            display: block;
            padding: $home__static-block-padding;
        }
    }

    &__cms-block-title {
        display: none;

        @include mq($screen-m) {
            display: block;
            color: $static__text-heading-color;
        }
    }

    &__link-list {
        display: block;

        @include mq($screen-m) {
            display: none;
        }
    }

    &__featured-categories {
        background: $home-background-color--accent;
        border-top: $home__featured-categories-border;
        border-bottom: $home__featured-categories-border;
        padding: 30px 0;
        width: 100%;

        @include mq($screen-m) {
            background: $home-background-color;
        }
    }

    &__payment-logos,
    &__need-help {
        display: none;
        margin-bottom: 20px;

        @include mq($screen-m) {
            display: block;
            padding: 0 0 0 $spacer--container;

            .need-help {
                border: $border-base;
                width: 100%;
            }
        }

        @include mq($screen-l) {

            .need-help {
                padding: 30px;
            }
        }
    }

    &__tiles {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
    }

    &__tile {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        border: 1px solid #b1bdc3;
        flex-basis: 100%;
        height: 250px;
        margin: 7px 0;
        overflow: hidden;
        flex-grow: 0;
        padding: 8px 15px;
        border-radius: 4px;

        @include mq($screen-m) {
            flex-basis: calc(50% - 7px);
        }

        & div:last-child {
            display: none;

            @include mq($screen-l) {
                display: block;
                flex-basis: calc(100% / 3);
                flex-shrink: 0;
                margin-left: 20px;

                img {
                    height: 100%;
                    max-height: 230px;
                }
            }
        }

        & h2 {
            font-size: 19px;
            color: $color-primary;
        }

        & p {
            color: $color-primary--accent-9;
            font-size: 16px;
        }

        & ul {
            color: $color-primary--accent-9;
            font-size: 16px;
            line-height: 24px;
            padding: 0 30px;
        }
    }

    // ---------------- Extends to style content based on migrated data. ---------------- //

    .featured-categories-wrapper {
        @extend #{$self}__featured-categories;
        width: 100vw;
        margin: 0 -#{$global-container-margin};

        @include mq(#{$max-content-width + 2 * $global-container-margin}) {
            margin: 0 calc((100vw - #{$max-content-width}) / -2);
        }
    }

    .page-content {
        display: none;

        @include mq($screen-m) {
            display: block;
            padding: 10px 0 20px;
        }
    }

    .container.ambanners {
        padding-top: 25px;
        margin-left: 0;
        margin-right: 0;
    }


    .ambanner-11 {
        img {
            margin-bottom: 20px;
        }
    }
}
