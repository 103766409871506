$dashboard__margin        : $spacer--large !default;
$dashboard__padding       : $spacer--medium !default;
$dashboard-link-item-color : $color-primary !default;
$dashboard-link-item-filter : none !default;

.dashboard {
    margin-top: $spacer--medium;
    margin-bottom: $spacer--medium;

    &__welcome {
        display: block;
        font-weight: bold;
        padding: 20px 15px;
        margin: 0 -15px 10px -15px;
        font-size: 35px;
        color: $color-primary--accent-5;
        background: $color-primary--accent-2;

        @include mq($screen-m) {
            padding: 40px 20px;
            margin: 0 0 20px 0;
            border-radius: 5px;
        }
    }

    &__last-order {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        border-radius: 5px;
        margin: 10px 0;
        padding: 10px;
        border: $border-base;

        @include mq($screen-m) {
            margin: 0 0 20px;
            padding: 20px;
        }
    }

    &__order-details {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        flex-basis: 100%;

        strong {
            flex-basis: 100%;
            padding: 8px 0;
        }

        span {
            flex-basis: 100%;
            padding: 8px 0;
            font-size: 14px;
            font-weight: normal;
        }

        @include mq($screen-m) {
            flex-basis: calc(100% / 3 * 2);

            span {
                flex-basis: 50%;
            }
        }
    }

    &__order-actions {
        flex-basis: 100%;

        @include mq($screen-m) {
            flex-basis: calc(100% / 3);
        }

        a {
            margin: 5px 0;
        }
    }

    &__actions {
        justify-content: space-between;
    }

    &__contact {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        margin-top: 20px;
        border-top: $border-base;

        &-default {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding: 8px 10px;
            flex-basis: 100%;
            border-radius: 5px;
            margin: 8px 0;
            border: $border-base;
            color: $color-primary;

            @include mq($screen-m) {
                flex-basis: calc(50% - 8px);
                margin: 0;

                &:first-of-type {
                    margin-right: 8px;
                }

                &:last-of-type {
                    margin-left: 8px;
                }
            }

            &-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-basis: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 16px;
                background: $color-primary;

                svg {
                    width: 25px;
                    height: 25px;
                    fill: #fff;
                }
            }

            &-text {
                strong {
                    display: block;
                    margin-top: 8px;
                }
            }
        }

        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 10px;

            @include mq($screen-m) {
                width: 75px;
                height: 75px;
                margin-right: 20px;
            }
        }

        &-details {
            flex-basis: calc(100% - 75px - 20px);
            padding: 10px;
            border-radius: 5px;
            flex-grow: 1;
            background: $color-primary--accent-2;
            color: $color-primary--accent-5;

            @include mq($screen-m) {
                padding: 20px;
            }

            strong {
                display: block;
                font-size: 20px;
                padding-bottom: 10px;
            }

            span {
                font-size: 16px;
            }

            svg {
                display: inline-block;
                width: 18px;
                height: 18px;
                vertical-align: middle;
                margin: 0 10px;
                fill: $color-primary--accent-5;

                &:first-of-type {
                    margin-left: 0;
                }
            }

            a {
                color: inherit;
            }

            button {
                margin-top: 20px;
            }
        }

        &-email {
            .contact-form__header {
                text-align: center;
                svg.icon {
                    margin: 0 auto;
                }
            }
        }
    }

    &__promise {
        padding: 20px;
        border-radius: 5px;
        background: $color-primary--accent-2;
    }

    &__lists {
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        justify-content: space-between;

        @include mq($screen-m) {
            flex-flow: row nowrap;
        }
    }

    &__lists-item {
        display: flex;
        flex-flow: row wrap;
        margin-top: 20px;
        padding: 40px;
        flex-basis: calc(50% - 10px);
        border-radius: 5px;
        border: $border-base;

        @include mq(false, $screen-m) {
            flex-basis: 100%;
            padding: 20px 10px;
        }

        .dashboard__you & {
            border: none;
            align-content: flex-start;
            margin-top: 0;
            color: $color-primary--accent-5;

            &--profile {
                background: $color-primary--accent-2 url('../images/dashboard-profile.svg') no-repeat 95% 10%;
                background-size: 50px;

                @include mq($screen-m) {
                    background-size: 100px;
                }
            }

            &--company {
                background: $color-primary--accent-2 url('../images/dashboard-company.png') no-repeat 95% 10%;
                background-size: 50px;

                @include mq($screen-m) {
                    background-size: 100px;
                }
            }

            > strong {
                font-size: 16px;
                margin-bottom: 20px;
            }

            svg {
                display: inline-block;
                width: 18px;
                height: 18px;
                vertical-align: middle;
                margin: 0 10px;
                fill: $color-primary--accent-5;

                &:first-of-type {
                    margin-left: 0;
                }
            }
        }

        > * {
            flex-basis: 100%;
        }

        > h3 {
            margin-bottom: 20px;
        }

        > span, > strong {
            margin-bottom: 10px;
        }

        .button {
            align-self: flex-end;
            font-weight: 600;
            margin-top: 10px;
            font-size: 14px;

            @include mq($screen-m) {
                font-size: 16px;
            }
        }
    }

    &__links {
        display: none;

        @include mq($screen-m) {
            display: flex;
            flex-flow: row wrap;
            align-items: stretch;
            justify-content: flex-start;
            gap: 18px;
            margin-top: 20px;
            padding-top: 20px;
            border-top: $border-base;
        }
    }

    &__links-item {
        display: flex;
        flex-flow: row wrap;
        padding: 0 10px 0 40px;
        border-radius: 5px;
        border: $border-base;
        color: $dashboard-link-item-color;

        @include mq($screen-m) {
            flex-basis: calc(50% - 10px);
        }

        @include mq($screen-l) {
            flex-basis: calc(33% - 10px);
        }

        > strong {
            font-size: 16px;
            margin-bottom: 20px;
        }

        &.hidden {
            display: none;
        }
    }

    &__links-item-icon {
        display: flex;
        flex-flow: row wrap;
        flex-basis: 15%;

        .dashboard__notifications & {
            flex-basis: 40px;
        }

        > svg {
            width: auto;
            height: 80px;
            max-width: 100%;
            filter: $dashboard-link-item-filter;
        }
    }

    &__links-item-info {
        display: flex;
        flex-flow: row wrap;
        flex-basis: 85%;
        padding-left: 20px;
        flex-direction: column;
        justify-content: center;
    }

    &__links-item-count {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        padding: 0 5px;
        border-radius: 25px;
        min-width: 25px;
        height: 25px;
        font-size: 13px;
        font-weight: 600;
        margin: 0 20px 0 auto;
        color: #fff;
        background: $color-danger;
    }

    &__links--load-more {
        width: 100%;
        text-align: center;
        padding: 20px 0;
    }

    &__notifications {
        display: none;

        @include mq($screen-m) {
            display: flex;
            margin-top: 10px;
        }
    }

    &__spotlight {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        @include mq($screen-m) {
            flex-flow: row nowrap;
        }

        a {
            flex-basis: calc(50% - 8px);
            color: #fff;
            padding: 15px 20px 15px 95px;
            border-radius: 5px;

            @include mq(false, $screen-m) {
                flex-basis: 100%;
                margin: 5px 0;
                padding: 15px 10px 15px 85px;
            }

            &:hover {
                color: #fff;
            }

            strong {
                display: block;
                margin-bottom: 10px;
            }
        }

        &--latest {
            background: url("../images/new.png") no-repeat 20px 15px, linear-gradient(-90deg, rgba(71, 173, 76, 0.67) 0%, #47ad4c 99.72125435540067%);
            background-size: 50px, cover;
        }

        &--discount {
            background: url("../images/sale.png") no-repeat 20px 15px, linear-gradient(-269.99999999999966deg, #fa7400 0.5325336241367851%, #fa8e08 100%);
            background-size: 50px, cover;
        }
    }

    &__recently-viewed {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        &-item {
            flex-basis: 100%;
            padding: 20px;
            border-radius: 5px;
            margin-bottom: 20px;
            border: $border-base;
            color: $color-text-accent;

            &:hover {
                color: $color-text-accent;
            }

            img {
                width: auto;
                margin: 0 auto 20px;
            }

            span {
                font-weight: 600;
                @include line-clamp(2);
            }

            @include mq($screen-m) {
                flex-basis: calc(25% - 20px);
                margin-bottom: 0;
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .dashboard__you {
        a {
            display: inline-block;
            margin: 10px 0;
        }
    }

    .actions a {
        margin-bottom: 10px;
    }

    &__handler {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: $dashboard__margin;
    }

    &__title {
        display: block;
        font-size: 24px;
        font-weight: bold;
        flex-basis: 100%;
        padding: 10px 0;
        color: $color-text-default;

        @include mq($screen-m) {
            margin: 8px 0;
            padding: 20px 0;
        }

        &--border {
            padding-top: 15px;
            border-top: $border-base;
        }
    }

    &__item {
        margin-bottom: $dashboard__margin;
    }

    &__form {
        padding: 0 15px;

        &:first-child {
            padding-left: 5px;
        }

        &:last-child {
            padding-right: 5px;
        }
    }

    &__product {
        border: 0;
        padding: 0;
    }

    &__form {
        &--hidden {
            display: none;
        }
    }

    &__field-qty {
        max-width: 100px;
        text-align: right;
    }

    &__input {
        margin-bottom: $dashboard__margin;
    }

    &__label {
        margin: 0 20px 0 0;
    }

    &__link {
        display: inline-flex;
    }

    &__list {
        border: $border-base;
        border-radius: $border-radius;
        padding: 5px 10px;

        .nav.item {
            @extend .list__item;
            @extend .dashboard__list-item;
        }

        .nav.item > a {
            @extend .dashboard__list-link;
        }
    }

    &__list-item {
        padding: 0;
        margin: 0 5px 5px 0;
    }

    &__list-link {
        padding: $dashboard__padding;
    }

    a.underline {
        text-decoration: underline;
    }
}

.modal-dashboard-contact {
    height: 480px;
    bottom: -230px!important;
    .modal-footer {
        border-top: none!important;
        padding-top: 0!important;
    }
    .icon {
        width: 44px;
        height: 44px;
    }
    .modal-content {
        padding-bottom: 0!important;
    }
    .modal-inner-wrap {
        textarea {
            width: 100%;
            min-height: 100px;
            max-height: 22vh;
        }
    }
}

