.grouped-product-list-items {
    flex-basis: 100%;

    @include mq($screen-l) {
        flex-basis: calc(80% - 25px);
        margin-left: 20px;

        &.wide {
            flex-basis: 100%;
        }
    }

    h1 {
        font-weight: bold;
        font-size: 24px;
    }
}

.grouped-product-list-item {
    display: flex;
    flex-flow: row wrap;
    padding: $grouped-product-list-item-padding;
    margin: 0 #{-1 *$global-container-margin};
    border-bottom: $grouped-product-list-item-border;

    &.hidden {
        display: none;
    }

    @include mq($screen-m) {
        margin: 0;
        padding: $grouped-product-list-item-padding-\@medium;
    }

    @include mq($screen-xl) {
        flex-wrap: nowrap;
    }

    &__image {
        width: $grouped-product-list-item__image-width;
        margin-bottom: 10px;

        .sales-label-wrapper {
            text-align: center;
        }
    }

    .product-elabel {
        width: 35px;
        height: auto;
        cursor: pointer;

        @include mq($screen-m) {
            width: 50px;
        }
    }

    &__details {
        width: calc(100% - #{$grouped-product-list-item__image-width} - 10px);
        margin-left: 10px;

        @include mq($screen-m) {
            width: calc(50% - #{$grouped-product-list-item__image-width} - 10px);
        }

        @include mq($screen-l) {
            width: calc(70% - #{$grouped-product-list-item__image-width} - 10px);
        }

        @include mq($screen-xl) {
            width: 40%;
        }
    }

    h2 {
        font-size: $grouped-product-list-item__font-size--main;

        @include mq($screen-m) {
            font-size: $grouped-product-list-item__font-size--main\@medium;
        }
        
        .link {
            color: $grouped-product-list-item__name-color;
            font-weight: 600;
        }
    }

    &__price {
        flex-basis: 40%;

        @include mq($screen-mobile) {
            flex-basis: 42%;
        }

        @include mq($screen-m) {
            flex-basis: 20%;

            .price__value--regular {
                text-align: right;
            }
        }

        > .price-wrapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            @include mq($screen-l) {
                justify-content: flex-end;
                text-align: right;

                .price__value--old:before {
                    left: 0;
                }

                &--excl-vat-eco {
                    justify-content: flex-end;
                }
            }
        }

        .price-wrapper__eco {
            @include mq(false, $screen-m) {
                width: 100%;
            }

            line-height: normal;
            font-size: 10px;

            @include mq($screen-m) {
                font-size: 12px;
            }
        }
    }

    &__actions {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        align-self: flex-start;
        flex-basis: 60%;
        flex-grow: 1;

        @include mq($screen-mobile) {
            flex-basis: 58%;
        }

        @include mq($screen-m) {
            flex-basis: 30%;
        }

        @include mq($screen-l) {
            flex-basis: 35%;
        }
    }

    &__quantity-update {
        margin-right: 6px;

        .quantity-update {
            margin-top: 6px;
        }

        @include mq($screen-l) {
            margin-right: 5px;
        }

        @include mq($screen-xl) {
            margin-right: 10px;
        }
    }

    &__specifications {
        padding-left: 20px;

        li  {
            padding: 3px 0;
            color: $color-text-accent;

            &::marker {
                color: $color-text-accent-3;
            }
        }
    }

    &__dispatch-status {
        height: min-content;
        text-align: right;
        flex-basis: 100%;
        padding: 0;

        @include mq($screen-m) {
            padding: 10px 0;
        }

        .dispatch-status {
            @include mq(false, $screen-m) {

                &__explain {
                    margin-left: 5px;

                    .tooltip__icon {
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        font-size: 14px;
                    }
                }
            }

            &--non-stocked {
                padding: 0;
            }
        }
    }

    .tooltip:not(.tooltip--dispatch) {
        display: none;

        @include mq($screen-m) {
            display: block;
        }
    }

    .tooltip--shipping .tooltip__icon {
        display: block;
        line-height: 20px;
        font-weight: bold;
        margin: 0 10px;
    }

    .tooltip__content .price {
        font-size: 14px;
        font-weight: normal;
    }

    .unavailable {
        margin: 0 0 0 auto;

        @include mq(false, $screen-m) {
            width: 100%;
        }

        .button {
            width: 100%;
            padding: 0 40px;
            margin-bottom: 10px;
        }

        p {
            text-align: center;

            @include mq($screen-m) {
                text-align: right;
            }
        }
    }

    .stock__message {
        color: $color-primary;
    }

    &--hide-price {

        .grouped-product-list-item {

            &__price {
                justify-content: flex-end;
            }
        }
    }
}
