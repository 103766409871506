$color-primary-accent                      : $color-primary--accent-2 !default;

$need-help__background                     : $white !default;
$need-help__background-\@large             : none !default;
$need-help__padding                        : 20px !default;
$need-help__padding-\@large                : 0 !default;
$need-help__border                         : 1px solid $gray-light !default;
$need-help__bottom-gap                     : $spacer--medium !default;

$need-help__text-color                     : $color-text-accent !default;
$need-help__subtitle-text-color            : $color-text-accent !default;
$need-help__text-color-\@large             : $color-light !default;
$need-help__subtitle-text-color\@large     : $color-light !default;
$need-help__heading-border                 : 1px solid $gray-light !default;
$need-help__heading-font-weight            : $font-weight-bold !default;
$need-help__heading-font-weight-\@large    : $font-weight-bold !default;
$need-help__heading-font-size              : $font-size-large !default;
$need-help__heading-font-size-\@large      : $font-size-large !default;

$need-help__controls-height                : 32px !default;
$need-help__button-padding                 : 21px !default;
$need-help__button-height                  : 50px !default;
$need-help__button_container_gap           : 5px !default;
$need-help__button_container_gap-\@large   : 20px !default;
$need-help__button-background              : $color-primary !default;
$need-help__button-hover-background        : $color-primary-accent !default;
$need-help__button-color                   : $color-light !default;
$need-help__button-background-\@large      : $color-light !default;
$need-help__button-color-\@large           : $color-primary !default;
$need-help__button-icon-size               : 24px !default;
$need-help__button-icon-margin             : 0 10px 0 0 !default;
$need-help__button-border-bottom           : none !default;
$need-help__button--hover-background       : $color-primary--accent-2 !default;
$need-help__button--hover-background-light : $color-primary--accent !default;
$need-help__button--hover-color            : $color-primary !default;

$need-help__subtitle-text-color--light     : $color-text-accent !default;
$need-help__heading-color--light           : $color-text-accent !default;
$need-help__heading-third-color--light     : $color-text-accent !default;

    // Online pseudo element
$need-help__pseudo-online-size             : 20px !default;
$need-help__pseudo-online-background       : $color-alt !default;

$self  : '.need-help';

#{$self} {
    background: $need-help__background;
    padding: $need-help__padding;
    border: $need-help__border;

    @include mq($screen-l) {
        border: none;
        background: $need-help__background-\@large;
        padding: $need-help__padding-\@large;
    }

    &__heading {
        height: auto;
        text-align: left;
        margin: 0 20px 20px 0;
        padding: 0 0 15px 0;
        line-height: 1.2;
        color: $need-help__text-color;
        border-bottom: $need-help__heading-border;

        &--third-level {
            color: $need-help__text-color;
            font-weight: $need-help__heading-font-weight;
            font-size: $need-help__heading-font-size;
        }

        &--small-padding {
            padding: 5px 0;
        }

        @include mq($screen-l) {
            color: $need-help__text-color-\@large;
            border: none;

            &--third-level {
                color: $need-help__text-color-\@large;
                font-weight: $need-help__heading-font-weight-\@large;
                font-size: $need-help__heading-font-size-\@large;
            }
        }
    }

    &__controls {
        position: relative;
        height: auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        width: fit-content;
        margin-bottom: $need-help__bottom-gap;
        font-size: 0;

        @include mq($screen-s) {
            justify-content: flex-start;
        }
    }

    &--project {

        #{$self}__controls {
            width: 100%;

            @include mq($screen-s) {
                flex-wrap: nowrap;
            }
        }

        #{$self}__button-container {
            @include mq($screen-l) {
                max-width: none;
                width: 100%;
            }
        }
    }

    &__button-container {
        display: inline-block;
        width: 100%;

        @include mq(false, $screen-s) {
            &:first-child {
                margin-bottom: 8px;
            }
        }

        @include mq($screen-s) {
            margin: 0 $need-help__button_container_gap-\@large 0 0;
            width: calc(50% - #{$need-help__button_container_gap-\@large});
        }

        @include mq($screen-l) {
            max-width: 240px;
            width: auto;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    &__button {
        width: 100%;
        height: $need-help__button-height;
        background: $need-help__button-background;
        color: $need-help__button-color;
        border-bottom: $need-help__button-border-bottom;

        @include mq($screen-l) {
            background: $need-help__button-background-\@large;
            color: $need-help__button-color-\@large;
        }

        .button__icon {
            display: none;
            fill: $need-help__button-color;
            width: $need-help__button-icon-size;
            height: $need-help__button-icon-size;
            margin: $need-help__button-icon-margin;

            @include mq($screen-l) {
                display: inline;
                fill: $need-help__button-color-\@large;
            }
        }

        &--online::before {
            position: absolute;
            content: "";
            border-radius: 50%;
            left: -($need-help__pseudo-online-size / 2);
            top: -($need-help__pseudo-online-size / 2);
            background: $need-help__pseudo-online-background;
            width: $need-help__pseudo-online-size;
            height: $need-help__pseudo-online-size;
        }

        &--email {
            padding: 0 25px;
        }

        &--phone {
            position: relative;
            padding: 0 30px;
        }

        &:hover {
            cursor: pointer;
            outline: none;
            background: $need-help__button--hover-background;
            color: $need-help__button--hover-color;
        }

        &:focus {
            @include focus();
        }
    }

    &__button-subtitle {
        text-align: center;
        margin: 8px 0;
        color: $need-help__subtitle-text-color;

        @include mq($screen-l) {
            color: $need-help__subtitle-text-color\@large;
        }
    }

    &__footer {
        line-height: 1.2;
        color: $need-help__text-color;

        @include mq($screen-l) {
            color: $need-help__text-color-\@large;
        }
    }

    &--light {
        display: inline-block;

        #{$self}__button-container {
            margin: 0;

            @include mq($screen-xl) {
                margin: 0 $need-help__button_container_gap 0 0;
            }

            @include mq($screen-xxl) {
                margin: 0 $need-help__button_container_gap-\@large 0 0;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

        #{$self}__heading {
            margin: 0 0 5px 0;
            color: $need-help__heading-color--light;
        }

        #{$self}__heading--third-level {
            color: $need-help__heading-third-color--light;
        }

        #{$self}__button {
            background: $color-primary;
            color: $color-light;

            &:hover {
                background: $need-help__button--hover-background-light;
            }
        }

        .button__icon {
            fill: $color-light;
        }

        #{$self}__footer {
            color: $color-text-accent-3;
        }

        #{$self}__button-subtitle {
            color: $need-help__subtitle-text-color--light;
        }
    }

    hr {
        width: 100%;
        border-color: $border-color-base;
        border-top: 0;
        margin: 20px 0 5px;
    }
}
