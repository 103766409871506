$self: '.dispatch-status';

#{$self} {
    position: relative;
    display: inline-block;
    font-size: 12px;
    color: $dispatch-status-color;

    @include mq($screen-m) {
        font-size: 14px;
    }

    @include mq(false, $screen-m) {
        padding: 10px 0;
    }

    &__text {
        display: inline-block;
        max-width: 270px;
        width: fit-content;
        vertical-align: middle;

        @include mq($screen-m) {
            min-width: 180px;
            max-width: 300px;

            .cart-summary &,
            .checkout-index-index & {
                min-width: unset;
            }

            .grouped-product-list-item__dispatch-status &,
            .cart-list-item__dispatch-status & {
                min-width: unset;
            }
        }

        p {
            color: $dispatch-status-color;
        }
    }

    &__icon {
        display: inline-block;
        vertical-align: middle;
        width: 35px;
        height: 35px;
        margin-right: -5px;
        padding: 8px;
        fill: $dispatch-status-icon-fill;
    }

    &__explain {
        display: inline-block;
        vertical-align: middle;
        text-align: left;

        .cart-summary &,
        .checkout-index-index & {
            display: none;
        }

        @include mq(false, $screen-m) {
            z-index: 10;
        }

        .tooltip--attribute {
            display: block;
        }

        .tooltip__icon {
            width: 20px;
            height: 20px;
            font-size: 12px;
            font-weight: bold;
            line-height: 20px;
            text-align: center;
            color: $color-light;
        }
    }

    &__alternative {
        padding: 5px 0 25px;

        @include mq($screen-l) {
            padding: 15px 0 0;
        }

        &-title {
            display: inline-block;
            margin-bottom: 10px;
            color: $color-primary;
            font-weight: $font-weight-semi-bold;
            font-size: $font-size-medium;
        }

        .arrow {
            position: relative;
            padding: 3px 10px;
            margin-right: 20px;
            background: $color-alt;
            color: $color-light;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: -12.5px;
                border-top: 12.5px solid transparent;
                border-bottom: 12.5px solid transparent;
                border-left: 12.5px solid $color-alt;
            }
        }

        .related-products__inline-product-item {
            margin-bottom: 0;

            .inline-product-item__details {
                margin: 5px 0 0;
                padding: 0;
            }
        }
    }

    &--on-order {
        color: $dispatch-status--on-order-color;

        #{$self}__icon {
            padding: 0;
        }
    }

    &--detailed {

        @include mq($screen-m) {
            margin-top: 20px;
        }

        @include mq($screen-l) {
            margin-top: 0;
        }

        #{$self}__icon {
            padding: 10px;
            fill: $dispatch-status--detailed-icon-fill;

            @include mq($screen-m) {
                padding: 8px;
                padding-left: 0;
            }
        }

        #{$self}__text {

            @include mq($screen-m) {
                display: inline-block;
            }
        }

        .tooltip__icon {
            background: $color-alt;
        }

        &:hover {
            .tooltip__dispatch-content.success {
                display: block;
            }
        }
    }

    &--compact {
        justify-content: flex-end;

        #{$self}__icon {
            width: 24px;
            height: 24px;
            fill: $dispatch-status-icon-fill;
        }

        #{$self}__icon {
            padding: 0;
        }

        .tooltip__icon {
            background: $color-alt;
        }

        span {
            flex-basis: initial;
            font-size: $font-size-extra-small;

            @include mq($screen-m) {
                font-size: $font-size-base;
            }
        }

        &:hover {
            .tooltip__dispatch-content.success {
                display: block;
            }
        }
    }

    &--atp-14 {
        color: $color-cta;

        p {
            color: $color-cta;
        }

        .dispatch-status__icon {
            fill: $color-cta;
        }

        .tooltip__icon {
            background: $color-cta;
        }

        &:hover {
            .tooltip__dispatch-content.warning {
                display: block;
            }
        }
    }

    &--atp-over-14 {
        padding: 0;
        font-weight: bold;
        color: $color-cta;

        .dispatch-status__icon {
            padding: 0;
            fill: $color-cta;

            .cart-summary & {
                padding: 8px;
            }
        }

        .tooltip__icon {
            background: $color-cta;
        }

        &:hover {
            .tooltip__dispatch-content.danger {
                display: block;
            }
        }
    }

    &--non-stocked {
        padding: 10px;
        font-weight: 600;
        color: $color-primary;

        .cart-summary &,
        .checkout-index-index & {
            padding: 0;
        }

        .tooltip__icon {
            background: $color-primary;
        }

        @include mq(false, $screen-m) {

            .dispatch-status__text {
                width: auto;
            }
        }

        .dispatch-status__icon {
            display: none;

            .cart-summary &,
            .checkout-index-index & {
                display: inline-block;
                fill: $color-primary;
            }
        }

        &:hover {
            .tooltip__dispatch-content.primary {
                display: block;
            }
        }
    }

    &--atp-over-14,
    &--non-stocked {
        border-radius: 5px;
        margin: 10px 0;

        .dispatch-status__text {
            margin-right: 0;
        }

        #{$self}__explain {
            .cart-summary &,
            .checkout-index-index & {
                display: inline-block;
            }
        }

        @include mq($screen-m) {
            margin: 0;
        }
    }
}
