.field {
    @extend .input;
}

.input-text {
    @extend .input__field;
}

// Used to override the extension above for specific input types (eg. service request checkbox)
%reset-input-text-extension {
    width: auto;
    height: auto;
    line-height: 1.15;
    padding: 0;
    border: initial;
    border-radius: 0;
    transition: none;
    appearance: auto;
}
