$self : '.inline-product-item';

#{$self} {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: $inline-product-item__border;
    padding: $inline-product-item__padding;

    &.recommend {
        flex-wrap: wrap;
        justify-content: flex-start;

        > #{$self}__link {
            @include mq(false, $screen-m) {
                flex-basis: 25%;
            }
        }

        @include mq($screen-m) {
            flex-wrap: nowrap;
        }
    }

    .price__label--gross {
        display: none;
    }

    .price__value--gross {
        display: none;
    }

    &__link {
        display: block;
        color: $inline-product-item__name-color;
    }

    &__image-wrapper {
        width: $inline-product-item__image-width;

        .lazyload,
        .lazyloading {
            width: $inline-product-item__image-width--lazyload;
            height: $inline-product-item__image-height--lazyload;
        }

        img {
            width: $inline-product-item__image-width--lazyload;
            height: $inline-product-item__image-height--lazyload;
            object-fit: scale-down;
        }

        .loader__circle {
            margin-top: $inline-product-item__loader-margin-top;
        }
    }

    &__details {
        margin: $spacer--medium 0;
        padding: $inline-product-item__content-padding;

        &.right {
            margin-top: 0;
            margin-bottom: 5px;
            margin-left: auto;
            flex-basis: 100%;

            @include mq($screen-m) {
                flex-basis: auto;
                margin-top: $spacer--medium;
                margin-bottom: $spacer--medium;
            }
        }

        &.max {
            flex-basis: 66%;

            @include mq($screen-m) {
                flex-basis: auto;
                max-width: 450px;

                &.small {
                    max-width: 300px;
                }
            }
        }

        ul.checkmarks {
            list-style: none;
            padding-left: 0;

            li {
                margin-bottom: 8px;

                &:before {
                    content: '';
                    width: 1em;
                    height: 1em;
                    margin-right: 8px;
                    display: inline-block;
                    vertical-align: middle;
                    background-repeat: no-repeat;
                    background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 215.33783 164.89999"><path fill="#{$color-alt}" d="M 214.2,19.3 196.6,1.2 C 195.9,0.4 194.8,0 193.8,0 192.7,0 191.7,0.4 191,1.2 L 69,124.1 24.6,79.7 c -0.8,-0.8 -1.8,-1.2 -2.8,-1.2 -1,0 -2,0.4 -2.8,1.2 L 1.2,97.5 c -1.6,1.6 -1.6,4.1 0,5.7 l 56,56 c 3.6,3.6 8,5.7 11.7,5.7 5.3,0 9.9,-3.9 11.6,-5.5 h 0.1 L 214.3,25 c 1.4,-1.7 1.4,-4.2 -0.1,-5.7 z" /></svg> ');
                }
            }
        }
    }

    &__name {
        font-weight: $inline-product-item__name-font-weight;
        font-size: $inline-product-item__font-size;

        @include line-clamp(2);

        @include mq($screen-m) {
            font-size: $inline-product-item__font-size\@medium;
            font-weight: $inline-product-item__name-font-weight\@medium;
        }
    }

    &__cta {
        &.large {
            display: inline-block;
            float: right;

            a {
                height: 40px;
                font-size: 14px;
            }

            @include mq($screen-m) {
                display: block;
                float: none;
                width: 100%;
            }
        }
    }

    &__price {
        margin: $spacer--medium 0;

        &.large {
            display: inline-block;
            @include mq($screen-m) {
                display: block;
                .price {
                    font-size: 28px;
                }
            }
        }
    }

    &--compact {
        border: none;

        .amlabel-position-wrapper,
        .amasty-label-container {
            display: none !important;
        }

        #{$self}__image-wrapper {
            width: $inline-product-item--compact__image-width;

            .lazyload,
            .lazyloading {
                width: $inline-product-item--compact__image-width--lazyload;
                height: $inline-product-item--compact__image-height--lazyload;
            }

            img {
                width: $inline-product-item--compact__image-width--lazyload;
                height: $inline-product-item--compact__image-height--lazyload;
                object-fit: scale-down;
            }
        }

        #{$self}__price {
            margin: 0;
        }
    }

    &--with-cta {

        #{$self}__details {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            flex-basis: 80%;

            #{$self}__link {
                flex-basis: 100%;
            }
        }

        #{$self}__price {
            margin: $inline-product-item--cta__price-margin;
        }

        #{$self}__cta {

            .button {
                min-width: $inline-product-item__cta-width;
                height: $inline-product-item__cta-height;
                font-size: $inline-product-item__cta-font-size;
            }
        }
    }

    &--led-with-cta {
        @extend #{$self}--with-cta;
        border-left: $inline-product-item--cta__border-left;

        #{$self}__badge {
            display: inline-block;
            margin: 0 2px 2px 0;
        }

        #{$self}__details {

            #{$self}__link {
                line-height: 18px;
                flex-basis: calc(100% - #{$inline-product-item--cta__badge-margin} - #{$inline-product-item--cta__badge-width});
            }
        }
    }

    &--hide-price {

        button {
            margin: $spacer--medium 0 0 0;
        }
    }
}
