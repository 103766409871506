$self: '.grouped-filters';

#{$self} {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    flex-basis: 20%;
    max-width: 20%;

    &__wrapper {

        &:not(&--mobile) {
            display: none;

            @include mq($screen-l) {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                align-content: flex-start;
                align-items: flex-start;
                width: 100%;
            }
        }

        &--mobile {

            .grouped-filters__filter {
                padding: 15px;
                border-bottom: $grouped-filters__mobile-border-bottom;
            }

            .grouped-filter__label {
                font-size: $grouped-filters__mobile-filter-label-font-size;
                font-weight: $grouped-filters__mobile-filter-label-font-weight;
            }
        }

        .ais-CurrentRefinements-categoryLabel {
            padding: 0 10px 0 0;
        }

        .ais-CurrentRefinements-delete {
            margin-left: 5px;
        }

        .ais-RefinementList-list {
            li:nth-child(n + 6) {
                display: none;
            }

            &--open {
                li:nth-child(n + 6) {
                    display: list-item;
                }
            }

            .ais-RefinementList-item.hidden {
                display: none;
            }
        }
    }

    &__heading {
        width: 100%;
        color: $grouped-filters__heading-color;
        font-weight: $grouped-filters__heading-font-weight;
    }

    &__filter {
        width: 100%;
    }

    &__list {
        display: flex;
        flex-flow: row wrap;
        padding: 0;
        width: 100%;
    }
}
