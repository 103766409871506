$trustpilot-margin                          : 0 !default;
$trustpilot-color                           : $color-text-default !default;
$trustpilot-padding                         : 0 20px !default;
$trustpilot__image-width                    : 150px !default;
$trustpilot__image-margin                   : 0 auto 15px auto !default;
$trustpilot__logo-width                     : 92px !default;
$trustpilot__logo-margin                    : 0 5px !default;
$trustpilot__rating-text-font-weight        : $font-weight-base !default;
$trustpilot__rating-text-font-weight-bold   : $font-weight-semi-bold !default;
$trustpilot__rating-margin                  : 0 30px !default;
$trustpilot__rating-text-separator-margin   : 0 2px !default;

.trustpilot {
    margin: $trustpilot-margin;
    padding: $trustpilot-padding;
    color: $trustpilot-color;
    text-decoration: none;

    &:hover {
        color: $trustpilot-color;
        text-decoration: none;
    }

    @include mq($screen-l) {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }

    &__image {
        width: $trustpilot__image-width;
        margin: $trustpilot__image-margin;

        @include mq($screen-l) {
            margin: 0;
            display: inline-block;
        }
    }

    &__customerssay {
        margin: auto 15px auto 0;

        @include mq(false, $screen-m) {
            display: block;
            width: 100%;
            margin: 10px 0;
            text-align: center;
        }
    }

    &__trust-score {
        margin: 0 16px 0 0;
        font-size: 20px;

        @include mq(false, $screen-m) {
            display: block;
            width: 100%;
            margin: 10px 0;
            text-align: center;
        }
    }

    &__rating {
        text-align: center;

        @include mq($screen-l) {
            display: inline-block;
            margin: $trustpilot__rating-margin;
        }

        &-text {
            font-weight: $trustpilot__rating-text-font-weight;

            &--bold {
                font-weight: $trustpilot__rating-text-font-weight-bold;
            }

            &--with-separator::after {
                content: '|';
                margin: $trustpilot__rating-text-separator-margin;
            }
        }
    }

    &__logo {
        display: inline-block;
        vertical-align: bottom;
        width: $trustpilot__logo-width;
        margin: $trustpilot__logo-margin;
    }

    &--stars {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        .trustpilot {

            &__stars {
                margin-right: 10px;
            }

            &__score-average {
                margin-right: 10px;
                color: $color-trustpilot-green;
                font-weight: $font-weight-bold;
            }

            &__total-reviews {

                a {
                    line-height: 16px;
                    color: $color-primary;
                    font-size: $font-size-base;
                }
            }
        }
    }
}
