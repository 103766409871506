$breadcrumbs__margin                        : $spacer !default;
$breadcrumbs__padding                       : $spacer--medium $spacer !default;
$breadcrumbs__icon-margin--first            : $spacer--medium $spacer $spacer--medium 0 !default;
$breadcrumbs__color                         : $color-text-accent-2 !default;
$breadcrumbs__font-size                     : $font-size-small !default;

.breadcrumbs {
    display: flex;
    padding-top: 20px;

    @include mq($screen-m) {
        min-height: 61px;
    }

    &__list {
        padding: 0;
        margin: 0;
        width: 100%;

        @include mq($screen-m) {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
    }

    .icon {
        display: none;
        width: 14px;
        height: 14px;
        fill: $breadcrumbs__color;

        @include mq(false, $screen-m) {
            &.breadcrumbs__mobile {
                display: inline-block;
                vertical-align: middle;
                transform: rotate(180deg);
            }
        }

        @include mq($screen-m) {
            display: block;
        }

        &:first-child {
            margin: $breadcrumbs__icon-margin--first;
            width: 21px;
            height: 21px;
        }
    }

    &__item {
        display: none;
        align-items: stretch;
        color: $breadcrumbs__color;
        font-size: $breadcrumbs__font-size;

        @include mq(false, $screen-m) {
            &.breadcrumbs__mobile {
                display: inline-block;
                max-width: 90%;

                span {
                    @include line-clamp(1);
                }
            }
        }

        @include mq($screen-m) {
            display: flex;
        }

        &:first-child {
            .breadcrumbs__link {
                padding-left: 0;
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        padding: 5px;
        text-decoration: none;
        background-clip: content-box;
        color: $breadcrumbs__color;

        @include mq($screen-m) {
            padding: $breadcrumbs__padding;
        }

        &:hover {
            color: initial;
            text-decoration: underline;
        }
    }

    & + .page-main {
        .heading--page {
            margin-top: 0;
        }
    }
}
