.modal-new-visitor-popup {
    text-align: left;

    @include mq(false, $screen-m) {
        height: fit-content;
    }

    @include mq($screen-l) {
        position: absolute;
        margin: 50px 20px 0 auto;
        width: 450px !important;
        min-width: 450px !important;
        padding: 25px;
        border-radius: 5px;

        .modal-content {
            padding-left: 30px !important;
            padding-right: 30px !important;
        }
    }

    @include mq($screen-xl) {
        margin: 50px auto 0 63%;
    }

    .heading {

        h4 {
            margin-bottom: 0.5em;
            font-size: $font-size-medium;
            color: $color-text-accent;
        }

        ul {
            list-style: none;
            padding-left: 0;

            li {
                margin-bottom: 8px;

                &:before {
                    content: '';
                    width: 1em;
                    height: 1em;
                    margin-right: 8px;
                    display: inline-block;
                    vertical-align: middle;
                    background-repeat: no-repeat;
                    background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 215.33783 164.89999"><path fill="#{$color-alt}" d="M 214.2,19.3 196.6,1.2 C 195.9,0.4 194.8,0 193.8,0 192.7,0 191.7,0.4 191,1.2 L 69,124.1 24.6,79.7 c -0.8,-0.8 -1.8,-1.2 -2.8,-1.2 -1,0 -2,0.4 -2.8,1.2 L 1.2,97.5 c -1.6,1.6 -1.6,4.1 0,5.7 l 56,56 c 3.6,3.6 8,5.7 11.7,5.7 5.3,0 9.9,-3.9 11.6,-5.5 h 0.1 L 214.3,25 c 1.4,-1.7 1.4,-4.2 -0.1,-5.7 z" /></svg> ');
                }
            }
        }
    }

    .request {
        span {
            font-weight: normal;

            a {
                text-decoration: underline;
            }
        }

        button {
            margin: 0 !important;
            width: 100% !important;
        }
    }

    &.modal-popup {
        &.modal-slide {
            .modal-inner-wrap {
                height: auto;

                .modal__close-button {
                    top: -2px;
                    right: -2px;

                    &-icon {
                        width: 40px;
                        height: 40px;
                        fill: $color-primary;
                    }
                }
            }
        }
    }
}