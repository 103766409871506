.button {

    &--cta {
        background-image: none;

        &:hover, &:active {
            background-color: $button__background-hover--cta;
            background-image: none;
        }
    }

    &--cta-secondary {
        background-image: none;

        &:hover, &:active {
            background-color: $button__background-hover--cta-secondary;
            background-image: none;
        }
    }

    &--alt {
        background-image: none;

        &:hover, &:active {
            background-color: $button__background-hover--alt;
            background-image: none;
        }
    }

    &--light {
        background-image: none;

        &:hover, &:active {
            background-color: $button__background-hover--light;
            background-image: none;
        }
    }

    &--light-secondary {
        background-image: none;

        &:hover, &:active {
            background-color: $button__background-hover--light-secondary;
            background-image: none;
        }
    }

    &--light-secondary-inverted {
        background-image: none;

        &:hover, &:active {
            background-color: $button__background-hover--light-secondary-inverted;
            background-image: none;
        }
    }
}
