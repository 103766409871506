$grouped-filter-overlay__trigger-wrapper-border      : 1px solid $gray-light !default;
$grouped-filter-overlay__trigger-wrapper-background  : $gray-lightest !default;

$grouped-filter-overlay__trigger-size                : 41px !default;
$grouped-filter-overlay__trigger-margin              : 0 !default;
$grouped-filter-overlay__trigger-padding             : 8px !default;
$grouped-filter-overlay__trigger-border              : 1px solid $gray-light !default;
$grouped-filter-overlay__trigger-border-radius       : $border-radius;
$grouped-filter-overlay__trigger-background-color    : $white !default;

$grouped-filter-overlay__trigger-text-font-size      : $font-size-small !default;
$grouped-filter-overlay__trigger-text-line-height    : $font-size-small !default;
$grouped-filter-overlay__trigger-text-alignment      : center !default;
$grouped-filter-overlay__trigger-text-color          : $color-primary !default;
$grouped-filter-overlay__trigger-icon-size           : 18px !default;
$grouped-filter-overlay__trigger-text-font-weight    : $font-weight-semi-bold !default;
$grouped-filter-overlay__trigger-text-margin         : 0 0 0 4px !default;
$grouped-filter-overlay__trigger-icon-margin         : 0 0 2px 0 !default;
$grouped-filter-overlay__trigger-icon-margin\@medium : 0 0 4px 0 !default;
$grouped-filter-overlay__trigger-icon-padding        : 0 0 4px 0 !default;
$grouped-filter-overlay__trigger-icon-color          : $color-primary !default;
$grouped-filter-overlay__trigger-icon-color--hover   : $color-primary !default;
$grouped-filter-overlay__trigger-icon-size--close    : 42px !default;
$grouped-filter-overlay__trigger-margin-left--close  : 24px !default;

$grouped-filters__switcher-padding                   : 4px !default;
$grouped-filters__switcher-border-radius             : 22.5px !default;
$grouped-filters__switcher-font-weight               : $font-weight-normal !default;
$grouped-filters__switcher-transition                : $transition-base !default;
$grouped-filters__switcher-fill                      : $color-light !default;

$grouped-filter-overlay__content-spacing             : 35px !default;

$grouped-filter-overlay__content-top                 : 0 !default;
$grouped-filter-overlay__content-bottom              : 0 !default;
$grouped-filter-overlay__content-right               : calc(100% + 2px) !default;
$grouped-filter-overlay__content-height              : 100% !default;
$grouped-filter-overlay__content-max-width           : 100% !default;
$grouped-filter-overlay__content-max-width\@medium   : 100% !default;
$grouped-filter-overlay__content-max-height          : 100vh !default;
$grouped-filter-overlay__content-transform           : translateX(0) !default;
$grouped-filter-overlay__content-transform--open     : translateX($grouped-filter-overlay__content-right) !default;
$grouped-filter-overlay__content-padding             : 0 !default;
$grouped-filter-overlay__content-background-color    : $white !default;
$grouped-filter-overlay__content-transition          : $transition-base !default;
$grouped-filter-overlay__content-box-shadow          : 0 2px 4px 0 rgba(0, 0, 0, 0.5) !default;

$grouped-filter-overlay__content-title-color         : $color-primary !default;
$grouped-filter-overlay__content-title-font-weight   : $font-weight-bold !default;
$grouped-filter-overlay__content-title-font-size     : $font-size-base !default;
$grouped-filter-overlay__content-title-padding       : 25px 0 10px $grouped-filter-overlay__content-spacing !default;

$grouped-filter-overlay__overlay-background-color    : rgba(0, 0, 0, 0.6) !default;
$grouped-filter-overlay-active__subtitle-background  : $color-primary--accent-2 !default;
$grouped-filter-overlay-active__subtitle-color       : $color-primary !default;

$grouped-filter-overlay__title-font-size             : $font-size-medium !default;
$grouped-filter-overlay__title-font-color            : $color-text-accent !default;
$grouped-filter-overlay__title-font-weight           : $font-weight-bold !default;
$grouped-filter-overlay__title-padding               : 20px 0 !default;

$grouped-filter-overlay__item-padding-top            : 10px !default;
$grouped-filter-overlay__item-padding                : $grouped-filter-overlay__item-padding-top 0 $grouped-filter-overlay__item-padding-top $grouped-filter-overlay__content-spacing !default;

.grouped-filter-overlay {
    order: 4;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 25px;

    @include mq($screen-l) {
        display: none;
    }

    &__trigger-wrapper {
        display: flex;
        justify-content: flex-start;
        border-bottom: $grouped-filter-overlay__trigger-wrapper-border;
        background: $grouped-filter-overlay__trigger-wrapper-background;
    }

    &__trigger {
        position: relative;
        padding: 12px 25px;
        min-width: 140px;
        max-width: 320px;
        width: 49%;
        font-size: 14px;
        border-radius: $grouped-filters__switcher-border-radius;
        font-weight: $grouped-filters__switcher-font-weight;

        &:hover,
        &:active,
        &:focus {
            background: $color-primary;
            box-shadow: none;
        }

        @include mq($screen-l) {
            display: none;
        }
    }

    &__trigger-subtitle {
        position: absolute;
        height: 23px;
        bottom: -15px;
        padding: 5px 12px;
        border-radius: 10px;
        font-size: 12px;
        box-shadow: 0 2px 10px -6px rgba(0, 0, 0, 0.5);
        background: $grouped-filter-overlay-active__subtitle-background;
        color: $grouped-filter-overlay-active__subtitle-color;
    }

    &__trigger-icon {
        padding: $grouped-filters__switcher-padding;
        transition: $grouped-filters__switcher-transition;
        fill: $grouped-filters__switcher-fill;
        margin-right: 5px;
    }

    &__trigger-text {
        font-family: $font-family-base;
        font-size: $grouped-filter-overlay__trigger-text-font-size;
        font-weight: $grouped-filter-overlay__trigger-text-font-weight;
        line-height: $grouped-filter-overlay__trigger-text-line-height;
        text-align: $grouped-filter-overlay__trigger-text-alignment;
        margin: $grouped-filter-overlay__trigger-text-margin;
        color: $grouped-filter-overlay__trigger-text-color;
    }

    &__trigger--close {
        max-width: none;
        min-width: auto;
        width: auto;
        background-color: transparent;
        align-self: center;
        padding: 0;
        border: 0;
        margin-left: $grouped-filter-overlay__trigger-margin-left--close;

        .grouped-filter-overlay__trigger-icon {
            width: $grouped-filter-overlay__trigger-icon-size--close;
            height: $grouped-filter-overlay__trigger-icon-size--close;
            fill: $grouped-filter-overlay__trigger-icon-color;
        }

        &:hover,
        &:focus,
        &:active {
            background: transparent;
        }
    }

    .grouped-filter {
        margin: 0 5px 5px 0;
    }

    &__content {
        position: fixed;
        top: $grouped-filter-overlay__content-top;
        bottom: $grouped-filter-overlay__content-bottom;
        right: $grouped-filter-overlay__content-right;
        width: $grouped-filter-overlay__content-height;
        height: $grouped-filter-overlay__content-height;
        max-width: $grouped-filter-overlay__content-max-width;
        max-height: $grouped-filter-overlay__content-max-height;
        padding: $grouped-filter-overlay__content-padding;
        z-index: 100;
        overflow-y: auto;
        background: $grouped-filter-overlay__content-background-color;
        box-shadow: $grouped-filter-overlay__content-box-shadow;
        opacity: 1;
        transform: $grouped-filter-overlay__content-transform;
        transition: $grouped-filter-overlay__content-transition;
        visibility: hidden;

        @include mq($screen-l) {
            max-width: $grouped-filter-overlay__content-max-width\@medium;
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
        background-color: $grouped-filter-overlay__overlay-background-color;

        @include mq($screen-xl) {
            display: none;
        }
    }

    &__title {
        align-self: center;
        justify-content: center;
        text-align: center;
        width: calc(100% - (2 * (#{$grouped-filter-overlay__trigger-size} + #{$grouped-filter-overlay__trigger-margin-left--close})));
        font-size: $grouped-filter-overlay__title-font-size;
        color: $grouped-filter-overlay__title-font-color;
        font-weight: $grouped-filter-overlay__title-font-weight;
        padding: $grouped-filter-overlay__title-padding;
    }

    &__item {
        display: flex;
        padding: $grouped-filter-overlay__item-padding;

        &:first-child {
            padding-top: $grouped-filter-overlay__item-padding-top;
        }

        &:last-child {
            padding-bottom: $grouped-filter-overlay__item-padding-top;
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
        padding: 15px;

        .grouped-filter-overlay__trigger {
            width: 100%;
            border-radius: 5px;
        }
    }

    &--is-open {
        .grouped-filter-overlay__content {
            opacity: 1;
            transform: $grouped-filter-overlay__content-transform--open;
            visibility: visible;
        }

        .grouped-filter-overlay__overlay {
            width: 100%;
            opacity: 1;
        }
    }
}

#filter-no-result {
    padding: 20px 0;
}