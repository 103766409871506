$self : '.price-wrapper';

#{$self} {
    line-height: 26px;

    .price {
        display: inline-block;
        vertical-align: top;
    }

    &__vat {
        display: block;
        vertical-align: middle;
        font-size: 10px;
        font-weight: normal;
        color: $price-wrapper__vat-color;

        @include mq($screen-m) {
            display: inline-block;
            font-size: 12px;
        }
    }

    &--excl-vat-inline {

        #{$self}__vat {
            display: inline-block;
            vertical-align: bottom;
            font-size: $font-size-small;
        }
    }

    &--excl-vat-eco {
        display: flex;
        align-self: flex-end;

        #{$self}__eco {
            display: block;
            font-weight: 400;
            line-height: 22px;
            font-size: $font-size-small;
            color: $price-wrapper__eco-color;

            .price {
                font-weight: 400;
                font-size: $font-size-small;
            }

            @include mq($screen-m) {
                width: auto;
                font-size: $font-size-base;

                .price {
                    font-size: $font-size-base;
                }
            }
        }
    }

    &--excl-vat-eco-inline {
        @extend #{$self}--excl-vat-eco;

        display: flex;
        flex-flow: row wrap;
        align-items: flex-end;

        > .price {
            margin-right: 5px;
        }

        #{$self}__vat {
            display: inline-block;
            line-height: 22px;
            margin-right: 5px;
        }

        #{$self}__eco {
            flex-basis: 100%;

            @include mq($screen-m) {
                flex-basis: auto;
                display: inline-block;
                width: auto;
            }
        }
    }

    &--compact {
        @extend #{$self}--excl-vat-eco-inline;

        align-items: flex-end;
        line-height: 25px;

        .price {
            font-size: $font-size-base;
        }

        .price__value--special {
            display: inline-block;
        }

        .price__value--old {
            font-size: $font-size-small;
        }

        #{$self}__vat {
            display: inline-block;
            font-size: $font-size-extra-small;
        }

        #{$self}__eco {
            display: inline-block;
            width: auto;
            flex-basis: auto;
            margin-left: 5px;
            font-size: $font-size-small;

            .price {
                font-size: $font-size-small;
            }
        }
    }
}

.tax-label {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    color: #5d5d5d;
    font-weight:lighter;
}
.dispatch-status .tax-label {
    color: inherit !important;
    font-size: smaller !important;
}

.tax-display-excluding {

    .minicart-content__totals-row.price-including-tax.shipping-notice,
    .price-including-tax {
        display: none !important;
    }

    .totals.sub.incl {
        display: none;
    }

    .totals.shipping.incl {
        display: none;
    }

    .sales-order-totals {
        .subtotal_incl {
            display: none;
        }
        .shipping_incl {
            display: none;
        }
    }
}
.tax-display-including {

    .minicart-content__totals-row.price-excluding-tax.shipping-notice,
    .price-excluding-tax {
        display: none !important;
    }

    .totals.sub.excl {
        display: none;
    }

    .totals.shipping.excl {
        display: none;
    }

    .grand.totals.excl {
        display: none;
    }

    .totals-tax {
        display: none;
    }

    .eco-tax {
        display: none !important;
    }

    .sales-order-totals {
        .subtotal_excl {
            display: none;
        }
        .shipping {
            display: none;
        }
        .tax {
            display: none;
        }
        .grand_total {
            display: none;
        }
    }
}

.tax-display-including:not(.tax-display-initialized),
.tax-display-excluding:not(.tax-display-initialized) {

    .minicart-content__totals-row.price-including-tax.shipping-notice,
    .price-including-tax {
        display: none;
    }

    .minicart-content__totals-row.price-excluding-tax.shipping-notice,
    .price-excluding-tax {
        display: none;
    }

    .totals.sub.excl {
        display: none;
    }

    .totals.sub.incl {
        display: none;
    }

    .totals.shipping.excl {
        display: none;
    }

    .totals.shipping.incl {
        display: none;
    }

    .grand.totals.excl {
        display: none;
    }

    .totals-tax {
        display: none;
    }

    .eco-tax {
        display: none;
    }
}
