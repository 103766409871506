$trustpilot__title-color: $color-primary !default;

.trustpilot-reviews {

    &__title {
        margin: 20px 0;
        font-weight: $font-weight-semi-bold;
        font-size: $font-size-base;
        color: $trustpilot__title-color;

        @include mq($screen-m) {
            margin-bottom: 25px;
            font-size: $font-size-medium;
        }
    }

    &__label {
        display: block;
        margin-bottom: 15px;
        font-weight: $font-weight-semi-bold;
        font-size: $font-size-base;
        color: $color-text-accent;

        @include mq($screen-m) {
            font-size: $font-size-medium;
        }
    }

    &__show-less {
        display: block;
    }

    &__show-more {
        display: block;
    }

    &__show-more,
    &__show-less {
        margin: 15px 0;

        &.hidden {
            display: none;
        }
    }

    .trustpilot-star-rating {
        margin-bottom: 15px;
    }
}
