// Input
$quantity-update__input-color                        : $color-primary !default;
$quantity-update__input-font-weight                  : $font-weight-bold !default;

// Button
$quantity-update__button--plus-background            : $color-primary--accent-2 !default;
$quantity-update__button--minus-background           : $gray-light !default;
$quantity-update__button--plus-color                 : $color-primary !default;
$quantity-update__button--minus-color                : $color-text-accent !default;

// Icon
$quantity-updater__icon-fill                         : $color-light !default;
$quantity-updater__icon-spacing                      : 12px !default;

.quantity-update {
    display: flex;
    align-items: center;
    width: 100px;
    height: 34px;
    border-radius: 15px;
    box-shadow: 0 2px 10px -6px rgba(0, 0, 0, 0.5);

    &__input {
        border: none;
        text-align: center;
        appearance: none;
        width: 34px;
        color: $quantity-update__input-color;
        font-weight: $quantity-update__input-font-weight;
    }

    &__button {
        min-width: initial;
        border-radius: 50%;
        height: 27px;
        margin: 3px;

        @include isIE() {
            min-width: 27px;
        }

        &:active {
            box-shadow: none;
        }

        &--plus,
        &--plus:hover,
        &--plus:active {
            color: $quantity-update__button--plus-color;
            background: $quantity-update__button--plus-background;
        }

        &--minus,
        &--minus:hover,
        &--minus:active {
            color: $quantity-update__button--minus-color;
            background: $quantity-update__button--minus-background;
        }
    }

    &__icon {
        fill: $quantity-updater__icon-fill;
        width: $quantity-updater__icon-spacing;
        height: $quantity-updater__icon-spacing;
    }

    // Disable spin buttons in number input type
    input[type="number"]::-webkit-outer-spin-button, // sass-lint:disable-line no-vendor-prefixes
    input[type="number"]::-webkit-inner-spin-button { // sass-lint:disable-line no-vendor-prefixes
        -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
        margin: 0;
    }

    input[type="number"] { // sass-lint:disable-line no-vendor-prefixes
        -moz-appearance: textfield; // sass-lint:disable-line no-vendor-prefixes
    }
}
