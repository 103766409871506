// Hide add-to-cart success message on PDP & Cart.
.catalog-product-view {
    .data-messages .message.message--success {
        display: none;
    }
}
.checkout-cart-index {
    .message.message-success {
        display: none;
    }
}

.modal-cart-loader {
    @extend .modal-base;
    text-align: center;
    margin: 0 auto;
    width: 80%;
    height: 160px;

    @include mq($screen-m) {
        height: auto;
        width: 15%;
        margin: 50px auto;
        box-shadow: none;
    }

    .modal__close-button {
        display: none;
    }

    .heading {
        font-size: $font-size-medium;
    }

    &._show ~ .modals-overlay {
        opacity: 0.3;
    }

    &.modal-popup,
    &.modal-slide {
        overflow-y: hidden !important;
        left: 0 !important;

        .modal-inner-wrap {
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.35);

            // Transition could be nicer on mobile, but removing it like this breaks the overlay. Let's keep it as is for now.
            //@include mq(false, $screen-m) {
            //    transition: none;
            //}
        }
    }

    .modal-content {
        img {
            width: 100%;
        }
    }
}
