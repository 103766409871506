$header__border-color                   : $gray-light !default;
$header__background-color               : $white !default;
$header__padding                        : 5px 0 !default;
$header__padding\@medium                : 0 !default;

$header__wrapper-padding                : 0 !default;
$header__wrapper-padding\@medium        : 12px 0 12px 0 !default;

$header__logo-padding                   : 12px 0 12px $spacer !default;
$header__logo-padding\@medium           : 0 0 0 $spacer !default;
$header__logo-max-width                 : 200px !default;
$header__logo-max-width-l               : 250px !default;

$header__buttons-width                  : calc(100% - 48px)  !default
$header__buttons-width\@medium          : calc(4 * 48px + 3 * #{$spacer}) !default;
$header__buttons-width\@extra-large     : calc(4 * 48px + 3 * #{$spacer--medium}) !default;

$header__buttons-min-height             : 50px  !default;

$header__search-wrapper-padding         : 0 0 0 10px !default;
$header__search-wrapper-padding\@medium : 0 24px !default;

$header__vat-switcher-text-color        : $white !default;
$header__vat-switcher-slider-background                             : $color-primary--accent-2 !default;
$header__vat-switcher-slider-background--checked                    : $color-primary--accent-2 !default;
$header__vat-switcher-slider-pseudo-background                      : url(https://d1kzq7drnx4xfx.cloudfront.net/skin/frontend/anylamp/default/images/ios-close.svg) $white no-repeat !default;
$header__vat-switcher-slider-pseudo-background--checked             : url(https://d1kzq7drnx4xfx.cloudfront.net/skin/frontend/anylamp/default/images/ios-checkmark.svg) $color-primary--accent-5 no-repeat !default;

.header {
    width: 100%;
    background: $header__background-color;
    z-index: 100;
    padding: $header__padding;

    @include mq($screen-m) {
        &:not(&--alternative) {
            position: fixed;
            top: 0;
            padding: $header__padding\@medium;
        }
    }

    @include mq($screen-l) {
        &:not(&--alternative) {
            border-bottom: none;
        }
    }

    &__wrapper {
        position: relative;
        justify-content: space-between;
        display: flex;
        flex-flow: row wrap;
        padding: $header__wrapper-padding;
        transition: 0.3s ease;
        @include mq($screen-m) {
            padding: $header__wrapper-padding\@medium;
        }

        @include mq($screen-l) {
            border-bottom: 1px solid $header__border-color;
        }
    }

    &__logo {
        order: 1;
        flex: 1 50%;

        @include mq($screen-m) {
            flex: 0 auto;
        }
    }

    &__search-wrapper {
        display: flex;
        order: 4;
        flex-flow: column nowrap;
        flex: 1 auto;
        justify-content: center;
        padding: $header__search-wrapper-padding;
        margin-top: 7px;

        @include mq($screen-m) {
            margin-top: 0;
            flex: 2 auto;
            order: 2;
            padding: $header__search-wrapper-padding\@medium;
            border-top: none;
        }
    }

    &__minicart {
        position: relative;
    }

    &__minicart-content {
        top: calc(100% - 1px);
        &.popup {
            position: absolute;
            right: 0;
            top: 55px;
            box-shadow: 0 5px 25px -5px rgba(0, 0, 0, 0.25);

            @include mq($screen-m) {
                width: 400px;
            }
        }
    }

    &__buttons {
        display: flex;
        order: 2;
        flex: 1 50%;
        max-width: $header__buttons-width;
        min-height: $header__buttons-min-height;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;

        @include mq($screen-m) {
            order: 3;
            flex: 0 auto;
            flex-wrap: nowrap;
            max-width: $header__buttons-width\@medium;
        }

        @include mq($screen-xl) {
            flex: 0 auto;
            max-width: $header__buttons-width\@extra-large;
        }
    }

    &-advice {
        display: none;

        @include mq($screen-m) {
            display: block;
            padding: 10px 0;
            background: $color-primary--accent-2;
            color: $color-text-default;
        }

        .header--fixed & {
            display: none;
        }

        .container {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
        }

        .register-wrapper {

            a {
                font-weight: normal;
            }
        }

        &__contact {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            svg {
                width: 20px;
                height: 20px;
            }

            a {
                font-weight: bold;
            }

            small {
                margin: 0 10px;
            }
        }

        &__account {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
        }

        &__account-item {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding: 0 10px;

            svg {
                margin: 0 5px;
            }
        }
    }

    &__actions-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    .website-switcher {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding-right: 35px;

        &__flag {
            padding: 0 10px;

            &.active {
                background: #d2f2ff;
                border: 1px solid #1c71b8;
                padding: 5px;
            }

            @include mq($screen-m) {
                padding: 0 4px;
            }
        }
    }

    .language-switcher {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding-right: 35px;

        &__language {
            display: block;
            margin: 0;
            text-transform: lowercase;

            .button {
                min-width: auto;
            }

            svg {
                display: none;
            }
        }
        .language-switcher__language-button {
            text-transform: lowercase;
        }
    }

    &--fixed {
        .header__wrapper {
            padding: 12px 0 0 0;
        }

        .usp-bar {
            display: none;
        }

        .logo__slogan {
            display: none;
        }
    }

    &__side-menu {
        flex: 0 auto;
        margin-top: 7px;
        @include mq($screen-m) {
            margin-top: 0;
        }
    }

    &__vat-switcher {
        @include mq(false, $screen-xl) {
            display: none;
        }

        align-self: center;
        color: $header__vat-switcher-text-color;

        .vat-switcher {
            &__title {
                color: $header__vat-switcher-text-color;
            }

            &__label {
                margin: 0;
                align-self: center;
            }

            &__slider {
                background: $header__vat-switcher-slider-background;

                &:before {
                    background: $header__vat-switcher-slider-pseudo-background;
                }
            }
        }

        &--checked {
            .vat-switcher {
                &__slider {
                    background: $header__vat-switcher-slider-background--checked;

                    &:before {
                        background: $header__vat-switcher-slider-pseudo-background--checked;
                        transform: translateX(100%);
                    }
                }
            }
        }
    }

    &--alternative {
        margin-bottom: 10px;
        border-bottom: $border-base;
        padding: $spacer--medium 0;

        .logo__slogan {
            display: none;
        }

        .header__wrapper {
            padding: 0;
        }

        .header__logo {
            flex: 1 100%;

            @include mq($screen-m) {
                flex: 1 50%;
            }

            .logo__image {
                max-width: $header__logo-max-width;
                margin: 0 auto;

                @include mq($screen-m) {
                    margin: 0;
                }

                @include mq($screen-l) {
                    max-width: $header__logo-max-width-l;
                }
            }
        }
    }
}

.wwl-login-popup {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    padding: 25px 36px;
    background: #fff;

    @include mq($screen-m) {
        position: absolute;
        top: 85px;
        width: 387px;
        height: auto;
        border-radius: 5px;
        box-shadow: 0 5px 25px -5px rgba(0, 0, 0, 0.25);

        &:before {
            content: '';
            position: absolute;
            top: -22px;
            left: 50%;
            transform: translateX(-50%);
            border-style: solid;
            border-width: 0 16px 22px 16px;
            border-color: transparent transparent #f4f4f4 transparent;
        }

        &:after {
            content: '';
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            border-style: solid;
            border-width: 0 15px 20px 15px;
            border-color: transparent transparent #fff transparent;
        }
    }

    &.active {
        display: block;
    }

    label {
        margin-bottom: 8px;
        font-weight: bold;
        color: $color-text-accent;
    }

    &__forgot {
        display: flex;
        align-items: center;
        margin: 5px 0 15px 0;

        .icon {
            width: 14px;
            height: 14px;
            fill: $color-primary;
        }
    }

    .field-password {
        position: relative;

        svg {
            position: absolute;
            top: 38px;
            right: 13px;
            width: 24px;
            height: 16px;
            padding: 1px;
            fill: $color-primary;
        }
    }

    .input__field {
        height: 35px;
    }

    .button {
        height: 45px;
    }

    .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 30px;
        height: 30px;
        padding: 0;
        fill: $color-primary;
    }

    &__heading {
        display: block;
        margin-bottom: 25px;
        color: $color-primary;
        font-size: 16px;
        font-weight: bold;
    }

    &__register {
        display: block;
        padding: 10px;

        span {
            display: block;
            margin-bottom: 10px;
        }

        a {
            margin-top: 5px;
            width: 100%;
        }
    }

    hr {
        border: none;
        height: 1px;
        margin: 25px -36px 10px;
        background: $border-color-base;
    }

    &__error-message span {
        display: block;
        margin: 5px 0;
        color: $color-danger;
    }
}
