.modal-request-callback {
    @extend .modal-request-quote;

    .heading {

        .icon {
            margin: 5px auto;
        }

        h4 {
            margin-bottom: 0.5em;
        }

        a {
            text-decoration: underline;
        }
    }

    .request {

        &__form {

            .input__field {
                height: 45px;
                border-radius: $border-radius;
            }

            label {
                text-align: left;
                color: $color-text-accent;
                font-weight: $font-weight-semi-bold;

                &.error {
                    margin-top: 5px;
                    color: $message__color--error;
                }
            }
        }

        &__actions {

            .button {
                margin-top: 16px;
            }
        }
    }
}

.modal-request-single-quote,
.modal-installer-contact-pdp {
    @extend .modal-request-callback;

    .installer-contact-pdp__radios {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        @include mq($screen-l) {
            justify-content: space-between;
            flex-wrap: nowrap;
        }
    }

    .installer-contact-pdp__radio {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin: 0 8px 8px;
        width: auto;

        @include mq($screen-l) {
            margin: 0 8px;
            width: 100%;
        }

        label {
            margin: 0 0 0 8px;

            &[generated="true"] {
                position: absolute;
                top: -37px;
                left: 130px;
            }
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin: 0;
        }
    }

    .heading-icon-envelope {
        &:before {
            content: '';
            display: block;
            margin: 0 auto 8px;
            width: 24px;
            height: 25px;
            background: svg-uri($envelope-icon-svg) no-repeat;
        }
    }
}