.tooltip {
    position: relative;

    &--attribute {
        @include mq(false, $screen-m) {
            display: none;
        }
    }

    &__icon:not(.product-view__wishlist-add) {
        width: 20px;
        height: 20px;
        display: block;
        border-radius: 15px;
        cursor: pointer;
        text-align: center;
        font-size: 12px;
        background: $product-tooltip-background-color;
        border: $product-tooltip-border;
        color: $product-tooltip-color;
    }

    &__content {
        width: 230px;
        background-color: $color-primary--accent-2;
        border: 0;
        box-shadow: none;
        color: $color-primary--accent-5;
        display: none;
        left: -20px;
        line-height: 16px;
        padding: 20px;
        position: absolute;
        margin-top: 17px;
        z-index: 10;
        border-radius: $border-radius;
        font-weight: normal;

        .product-view__wishlist-add & {
            left: 50%;
            top: 130%;
            transform: translate(-50%, -50%);
            font-size: 14px;

            &:before {
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: -15px;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 25px solid $color-primary--accent-2;
            left: 15px;
        }
    }

    &__dispatch-content {
        display: none;

        width: 230px;
        background-color: $color-primary;
        border: 0;
        box-shadow: none;
        color: #fff;
        right: 0;
        line-height: 16px;
        padding: 20px;
        position: absolute;
        margin-top: 17px;
        z-index: 10;
        border-radius: $border-radius;
        font-weight: normal;

        &:before {
            content: '';
            position: absolute;
            top: -14px;
            right: 10px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 15px solid $color-primary;
        }
    }

    &:hover {
        .tooltip__content {
            display: block;
        }
    }
}

@include mq(false, $screen-m) {
    .tooltip {
        &__content {
            left: 50%;
            transform: translateX(-50%);

            &:before {
                left: calc(50% - 5px);
                transform: translateX(-50%);
            }
        }
    }
}
