$cross-sell-products-background-\@large         :   $gray-lightest !default;

#cart-crosssells {
    padding: 0;

    @include mq($screen-l) {
        padding: 10px 20px 0 0;
    }
}

.cart-cross-sell-placeholder {
    @include mq($screen-l) {
        margin-bottom: 700px;
    }
}

.cross-sell-products {
    display: flex;
    flex-flow: row nowrap;
    overflow-x: scroll;

    @include mq($screen-l) {
        flex-wrap: wrap;
        overflow-x: hidden;
        max-width: 100%;
        padding: 20px 40px 20px 40px;
        background: $cross-sell-products-background-\@large;
    }

    &__title {
        flex-basis: 100%;
        color: $color-primary;
        margin-top:5px;

        @include mq($screen-l) {
            margin-top:0;
            margin-bottom: 15px;
        }
    }

    &__cross-sell-product-item {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 100%;
        margin: 0 10px 15px 0;

        &:last-child {
            margin-right: 0;
        }

        @include mq($screen-s) {
            width: calc(100% / 2 - #{$spacer--medium});
            margin-right: 10px;
        }

        @include mq($screen-m) {
            width: calc(100% / 3 - #{$spacer--medium});
        }
    }
}
