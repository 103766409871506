$mega-menu__link-backgroundcolor--active: $white !default;
$mega-menu__link-transition: none !default;
$mega-menu__item-separator-color: transparent !default;

.mega-menu {
    &__item {
        @include mq($screen-m) {
            &:focus,
            &:hover {
                background: $mega-menu__link-backgroundcolor--active;

                .mega-menu__link {
                    color: $mega-menu__link-color--active;
                }
            }
        }
    }

    &__link {
        transition: $mega-menu__link-transition;
    }
    .hide {
        display: none;
    }
}

@include mq($screen-m) {
    body::before {
        position: fixed;
        z-index: -3;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        background-color: transparent;
    }
    body.mega-menu-active {
        &::before {
            z-index: 3;
            transition: background-color .3s cubic-bezier(0,0,.3,1);
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
}
