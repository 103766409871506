.cms-faq {

    h2 {
        margin-top: 40px;
    }

    dl {
        &:last-child {
            margin-bottom: 40px;
        }
    }

    dt {
        position: relative;
        padding: 10px 20px 10px 0;
        font-size: 17px;
        cursor: pointer;
        color: $color-primary;
        border-bottom: 1px solid $color-primary--accent-9;

        &:after {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 30px;
            font-weight: $font-weight-bold;
            content: "+";
        }
    }

    dd {
        display: none;

        &.active {
            display: block;
            padding-bottom: 10px;
            padding-top: 10px;
            font-size: 16px;
            color: $color-text-default;
            border-bottom: 1px solid $color-primary--accent-9;
        }

        p {
            padding-top: 10px;
            font-size: 16px;
        }
    }
}