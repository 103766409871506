.register-wrapper {

    &__login-link {
        background: url('../images/icons/login.svg') no-repeat center left;
        background-size: 24px 24px;
        padding: 24px 35px;
        line-height: 30px;
        font-weight: $font-weight-semi-bold;
    }

    &__register-link {
        height: 100%;
        padding: 5px 5px 5px 15px;

        .icon {
            width: 14px;
            height: 14px;
            margin: 0 0 0 5px;
            fill: $color-primary;
        }
    }
}