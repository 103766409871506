.modal-wishlist-delete {
  @extend .modal-request-quote;

  &.modal-slide{
    top: 70%;
  }

  .modal-header{
    padding-top: 40px;
    .heading {
      color: $color-text-accent;
      font-weight: bold;
    }
  }

  &.modal-popup {
    &.modal-slide {
      .modal-inner-wrap {
        height: auto;
      }
    }
  }
  .modal-content.checkout-address-fields{
    padding-bottom: 10px;
  }
  .modal-wrapper {
    p {
      font-weight: $font-weight-semi-bold;
      font-size: 16px;
      text-align: center;
      margin: 0 auto;
      color: $color-text-accent;
      @include mq($screen-m) {
        width: 260px;
      }
    }
  }

  footer.modal-footer.checkout-address-footer {
    @include mq($screen-m) {
      border-top: none;
      padding-top: 10px;
    }
  }

  .modal-footer {
    .button {
      display: block;
      margin: 10px auto;
      width: 100%;

      @include mq($screen-m) {
        width: 260px;
      }

      &--cta {
        font-weight: $font-weight-bold;
      }

      &--light {
        font-size: $font-size-base;
      }
    }
  }
}

.modal-wishlist-booklet-download {
  .modal-header {
    padding-top: 12px;
    padding-bottom: 42px;
    .button__icon {
      fill: #0b92e0;
      width: 40px;
      height: 40px;
    }
  }
  .icon {
    margin: 0 auto;
    width: 44px;
    height: 44px;
  }
  .label {
    padding-top: 10px;
    margin: 10px 0;
    font-weight: 600;
    font-size: 16px;
    color: #2d2d2d;
    text-align: left;
  }
  input {
    width: 100%;
    margin-top: 0;
    padding: 0 20px;
    height: 45px;
    border: 1px solid #ebebeb;
    border-radius: 5px;
  }
}